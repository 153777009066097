@font-face {
  font-family: 'Eastman';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Eastman"), url("./fonts/eastman/Eastman-Condensed-Extrabold.woff2") format('woff2');
  src: local("Eastman"), url("./fonts/eastman/Eastman-Condensed-Extrabold.woff") format('woff');
  src: local("Eastman"), url("./fonts/eastman/Eastman-Condensed-Extrabold.otf") format('opentype');
}
@font-face {
  font-family: 'Eastman';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Eastman"), url("./fonts/eastman/Eastman-Condensed-Extrabold.woff2") format('woff2');
  src: local("Eastman"), url("./fonts/eastman/Eastman-Condensed-Extrabold.woff") format('woff');
  src: local("Eastman"), url("./fonts/eastman/Eastman-Condensed-Extrabold.otf") format('opentype');
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}


html {
  --cursor-color: rgb(17, 87, 64)
}

html.dark-mode {
  --cursor-color: #fff
}

html,
body {
  background: #262626;
  font-size: 16px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 400;
}

@media (min-width: 768px) {
  html,
  body {
    font-size: 18px;
  }
}

/**************************************

TEXT

**************************************/
p,
.p {
  font-size: 1rem;
  font-weight: normal;
  text-transform: none;
}
h1,
.h1 {
  font-family: 'Eastman', 'Arial Narrow Bold', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  font-size: 3rem;
  line-height: 3rem;
}

h2,
.h2 {
  font-family: 'Eastman', 'Arial Narrow Bold', sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 3.6111111rem;
  line-height: 3.6111111rem;
}

h3,
.h3 {
  font-family: 'Eastman', 'Arial Narrow Bold', sans-serif;
  font-size: 1.666666rem;
  line-height: 1.8rem;
  text-transform: uppercase;
  font-weight: 400;
}

h4,
.h4 {
  font-size: 1.55555rem;
  line-height: 2.11111rem;
  font-weight: 400;
  letter-spacing: 0.02em;
}

blockquote * {
  font-family: 'Eastman', 'Arial Narrow Bold', sans-serif;
}



/**************************************

BUTTONS

**************************************/
.btn {
  line-height: 0.888888rem;
  font-size: 0.777777rem;
  text-transform: uppercase;
  padding: 0.777777rem 1.055555rem;
  letter-spacing: 0.1em;
  transition: all 0.25s ease;
}
.btn.btn-primary {
  background: rgba(255, 255, 255, 0.12);
  border: 2px solid #fff;
  color: #fff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background: #fff;
  color: #262626;
}


/**************************************

MISC BLOCK LEVEL STYLES

**************************************/
hr {
  max-width: 25vw;
}


/**************************************

BACKGROUNDS

**************************************/
.bg-gray {
  background: #262626;
}
.bg-light-gray {
  background: #4f4f4f;
}
.bg-beige {
  background: #f4f1f0;
}




/**************************************

BORDERS

**************************************/

@media (min-width: 992px) {
  .border-lft-lg {
    border-left: 1px solid #000;
  }
}























/**************************************

ISSUU THROWAWAY

**************************************/
.issuu-embed-container {

  position: relative;

  padding-bottom: 56.25%;
  /* set the aspect ratio here as (height / width) * 100% */

  height: 0;

  overflow: hidden;

  max-width: 100%;

}

.issuu-embed-container iframe {

  position: absolute;

  top: 0;

  left: 0;

  width: 100%;

  height: 100%;

}