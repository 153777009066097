/**************************************

MISC TEXT UTILITIES

**************************************/
.text-shadowed {
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
}
.text-orange {
  color: #ea6218;
}
a.text-orange {
  text-decoration: none;
}
a.text-orange:hover,
a.text-orange:focus,
a.text-orange:active,
a.text-orange.active
 {
  text-decoration: underline;
}



.border-gray {
  box-shadow: 1px 1px 0 0 #c6c6c6, inset 1px 1px 0 0 #c6c6c6;
}










@media (orientation: portrait) {
  .d-portrait-none {
    display: none;
  }
}

@media (orientation: landscape) {
  .d-landscape-none {
    display: none;
  }
}